import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import { BreadCrumb, Section } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import BgImg from '../images/servicebg.jpg'
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"

const SectionBg = styled(Section)`
 &:after{
  background-size: 50%;
  ${BreakpointDown.xl` 
    display:none; 
  `}
 }
 .container{
   position: relative;
   z-index:2;
 }
`
const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-top:210px;
  padding-bottom:165px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-top:120px;
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
&:first-child{
  padding-right:75px;
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  max-width:445px;
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  width:100%;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap; 
  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 40%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`

function ServicePage() {
  return (
    <Layout pageName="service">
      <SEO title="Service" />
      <SectionBg bgColor="#F8F8F8" pt="0" pb="0" bgAfter={`url(${BgImg})`}>
        <div className="container">
            <MonitoringSolutions>
              <MonitoringSolutionsItem>
                <BreadCrumb>
                  <Link to="/">Home</Link><Link to="/">Services</Link><Link to="/">Thermal Temperature</Link>
                </BreadCrumb>
                <ServiceTitle>Thermal Temperature Monitoring Solutions</ServiceTitle>
                <MonitoringSolutionsItemContent>
                  Thermal Temperature Monitoring technology enables quick detection of elevated skin temperatures. Thermal imaging equipment can easily be installed and implemented to detect elevated skin temperature in environments such as airports, hospitals, clinics, office buildings, schools, and any large public gathering location. The solution is a screening tool that can be used to identify individuals with elevated skin on or entering their premises. WYN Technologies has installed these devices in many school systems, medical clinics, manufacturing businesses, city government facilities and restaurants just to name a few across North Carolina and other states. Our technology can also be accompanied with a network video recorder and monitor connected either through WI-FI or data cable to view temperatures from safe distances. If a high temperature or no mask is indicated the system can send alerts via email or mobile app to the appropriate groups or individuals.
              </MonitoringSolutionsItemContent>
              </MonitoringSolutionsItem>
            </MonitoringSolutions>
        </div>
      </SectionBg>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
            <MonitoringSolutionsBottom>
              <MonitoringSolutionsBottomLeft>
                <InnerText>We ensure that you get the right technologies and best products</InnerText>
              </MonitoringSolutionsBottomLeft>
              <MonitoringSolutionsBottomRight>
                <BtnWrapper>
                  <PrimaryLinkButton text="Solution You’re Looking!" />
                </BtnWrapper>
              </MonitoringSolutionsBottomRight>
            </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <OurSolution />
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <Faq />
      <LetastArticles />
    </Layout>
  )
}

export default ServicePage
